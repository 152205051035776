.ckeditorTextEditor {
  :global .ck-editor {
    display: flex;
    flex-direction: column-reverse;
  }

  :global .ck-toolbar {
    border-bottom-width: 1px !important;
    border-top-width: 0px !important;
    border-bottom-left-radius: var(--ck-border-radius) !important;
    border-bottom-right-radius: var(--ck-border-radius) !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  :global .ck-content {
    border-top-left-radius: var(--ck-border-radius) !important;
    border-top-right-radius: var(--ck-border-radius) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  ol, ul, menu {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
}