.richTypographyText {
  > p {
    display: inline;
  }

  a {
    text-decoration: underline;
    
    &:hover {
      color: #000;
    }
  }

  ol, ul, menu {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
}